<template>
    <div class="home-banner">
        <h1 class="index_title">
            欢迎来到大理卷烟厂IOC管理后台
        </h1>
    </div>
</template>

<script>
import { domain } from "@/siteInfo.js";
export default {
    data() {
        return {
            text: '',
            t2: ''
        }
    },
    mounted() {

    },
    created() {
        if (this.$route.query.code != '' && this.$route.query.code != null && this.$route.query.code != undefined) {
            this.login()
        } else {
            let token = this.$store.state.token
            if (!token) {
                window.location.href = domain + "/admin/v1/authing/loginOut";
                // this.$router.push({ path: '/login' })
            }
        }
    },
    methods: {
        login() {
            let t = this
            t.$store
                .dispatch('login', {
                    'code': this.$route.query.code
                })
                .then(res => {
                    let { code, data, msg } = res
                    console.log(res)
                    if (code == 0) {
                        console.log('fsdfdsajk h')
                        t.$message.success('登录成功', 1)
                        t.$router.push({ path: '/index' })
                    } else {
                        t.$message.error('我是不是应该回总登录平台', 1)
                    }
                })
                .catch(err => {
                })
        },
    },
}
</script>
<style lang="less">
.home-banner {
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    bottom: 0;
    background: #fff;

    .index_title {
        position: absolute;
        text-align: center;
        width: 100%;
        top: 40%;
        transform: translateY(-50%);
    }
}
</style>